import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup, useMapEvent } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { Icon } from "leaflet";
import { useFetch } from './Hooks/useFetch';
import img from "./SelfieSamples/2.png"

const icon = new Icon({
    iconUrl: img,
    iconSize: [25, 25]
  });

function Map() {
    const url = `https://jakobseederbackend.jakobsitter.com/api/content/items/images`;
    const { status, data } = useFetch(url);
    console.log(data)
    const [res, setRes] = useState({});
    const [prompt, updatePrompt] = useState("trondheim");
    const [activePark, setActivePark] = useState(null);
    const [currentPos, setPos] = useState(null);

    const options = {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: 'fsq35MTaoyLz+r3K+G4sbFH6F/+/pMAUgAJH6Rs3nJP4gow='
        }
      };
    const search = async prompt => {
        fetch('https://api.foursquare.com/v3/places/search?query='+prompt+'&radius=100000', options)
        .then(response => response.json())
        .then(response => setRes(response))
        .then()
        .catch(err => console.error(err));
        console.log(res)
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          search(prompt);
        }
    }
    const handleClick = (e) => {
        console.log("click")
        setPos(e.latlng);
    }
    function handlePos(val) {
        setPos(val);
    }
    if(status == "fetched"){
    return(
        <div>
            <div>
            <MapContainer zoomControl={false} center={[30, 0]} zoom={2} minZoom={2} maxZoom={20} scrollWheelZoom={true} maxBounds={[[-90, -Infinity], [90, Infinity]]}
        maxBoundsViscosity={1}>
  
  <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    detectRetina={true}
      />
    {data.filter(function(img) {
  if (img.lat == undefined) {
    return false; // skip
  }
  return true;
}).map(
                      (image, index) =>  
        <Marker
          key={image._id}
          position={[
            image.lat,
            image.lng
          ]}
          onClick={() => {
            setActivePark(image);
          }}
          icon={new Icon({
            iconUrl: "https://api.jakobseeder.pro/images/"+image.image_id+".png",
            iconSize: [40, 40]
          })}
        >
          <Popup>
            {image.prompt}
          </Popup>
        </Marker>
      )}
      
</MapContainer>
            </div>            
        </div>
    )
            }
}
export default Map;