import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup, useMapEvent } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { Icon } from "leaflet";
import img from "./SelfieSamples/2.png"
const icon = new Icon({
    iconUrl: img,
    iconSize: [25, 25]
  });
function MovingMarker({ setPos }) {
    const [position, setPosition] = useState([30, 0])
    const markerRef = useRef(null)
    const map = useMapEvent('click', (e) => {
      setPosition(e.latlng)
      setPos(e.latlng)
    })
    
    return (
      <Marker
        position={position}
        ref={markerRef}
        icon={icon}
        >
      </Marker>
      
    )
  }
function Location({setLocation}) {
    const [currentPos, setPos] = useState(null);
    function handlePos(val) {
        setPos(val);
    }
    useEffect(() => {
        setLocation(currentPos)
    }, [currentPos]);
    return(
        <div>
            <div>
            <MapContainer zoomControl={false} center={[30, 0]} zoom={2} minZoom={2} maxZoom={20} scrollWheelZoom={true} maxBounds={[[-90, -Infinity], [90, Infinity]]}
        maxBoundsViscosity={1}>
  
  <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    detectRetina={true}
      />
      <MovingMarker setPos={handlePos} />
      
</MapContainer>
            </div>
            
        </div>
    )
}
export default Location;