import React, {useState, useRef, useEffect} from 'react';
import Replicate from "replicate";
import axios from "axios";
import LoaderSmall from "./LoaderSmall";
import { AiOutlineReload } from 'react-icons/ai';
import { IoShuffleSharp } from 'react-icons/io5';
import InputEmoji from "react-input-emoji";
import { Helper } from "./Hooks/Helper";
import Location from "./LocationAPI";
import { FaXTwitter } from "react-icons/fa6";
import { AiOutlineCamera,AiFillCloseCircle, AiOutlineCheckCircle, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { BsChatSquareText, BsCamera, BsEmojiHeartEyes, BsEmojiAngry, BsXOctagon, BsInstagram, BsChevronDown, BsCheck } from "react-icons/bs";
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group';
import { animated, useSpring, useTransition } from '@react-spring/web'

import pfp0 from './Img/Thumbs/0.png'
import pfp1 from './Img/Thumbs/1.png'
import pfp2 from './Img/Thumbs/2.png'
import pfp3 from './Img/Thumbs/3.png'
function CreateWidget(props) {
    const [prompt, updatePrompt] = useState('');
    const [img, updateImg] = useState();
    const [caption, setCaption] = useState();
    const [contentType, setType] = useState('text');
    const [isImg, setIsImg] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [mode, changeMode] = useState('default');
    const [activePfp, setPfp] = useState(0);
    const [isGenerated, setGenerated] = useState(false);
    const [activeScreen, setScreen] = useState('default_screen')
    const [postButtonDropdown, setPostButtonDropdown] = useState(false)
    const [isIg, setIg] = useState(false)
    const [isX, setX] = useState(false)
    const [hasContent, setContent] = useState(false)
    const [igUrl, setIgUrl] = useState(null)
    const [igLimitReached, setIgLimitReached] = useState(false)
    const [twitterError, setTwitterError] = useState(false)
    const [igId, setIgId] = useState(null)
    const [xId, setXId] = useState(null)
    function resetStates() {
        updatePrompt('')
        updateImg('')
        setCaption('')
        setType('default')
        setIsImg(false)
        setLoading(false)
        changeMode('default')
        setPfp(0)
        setGenerated(false)
        setScreen('default_screen')
    }
    useEffect(()=>{
        console.log(isImg)
        if(caption || isImg){
            setContent(true)
        } else {
            setContent(false)
        }
    }, [caption, isImg])
    useEffect(()=> {
        console.log(contentType)
    }, [contentType])
    const default_screen = (
        <>
        <div className="input-div sec">
                            <input type='textarea' key="random1" placeholder="What's on Jakob's mind?" onChange={e=>{e.preventDefault();setCaption(e.target.value); console.log('writing')}} value={caption}></input>
        </div>
        {contentType == 'image' &&
        <div className='img default'>
                            <img src={img}></img>
                                <div className='close' onClick={() => {updateImg("");setIsImg(false);setGenerated(false);}}>
                                    <AiFillCloseCircle />
                                </div>
        </div>
        }
        <>
        <div className={mode == "generate_image" ? 'icon camera active' : 'icon camera'} onClick={() => setScreen('generate_screen')}>
        <BsCamera />Create Image
        </div>
        <div className='post-section'>
        <div className={hasContent ? 'icon button post' : 'icon button post disabled'}>
            <div className='inner'>
                Post
                </div>
            </div>
            <div className='icon item' onClick={()=> setX(!isX)}><socialicon><FaXTwitter /></socialicon> X {isX && <BsCheck />}</div>
            <div className='icon item' onClick={()=> setIg(!isIg)}><socialicon><BsInstagram /></socialicon> Instagram {isIg && <BsCheck />}</div>
        </div>
        </>
        </>
    )
    const generate_screen = (
        <div className="image-div sec">
                            <div className="pre_placeholder">jakob</div>
                            <input type='input' placeholder="drinking a pumpkin spice latte in times square" onChange={(e)=>{updatePrompt(e.target.value);console.log('writing');}} value={prompt} onKeyPress={event => {
                if (event.key === 'Enter') {
                    generate(prompt)
                }
              }}></input>
                            <div className="button generate" onClick={()=>generate(prompt)}>Generate</div>
        </div>
    )
    const error_screen = (
        <div className="image-div sec">
            Something went wrong.. 
            
            <div className={'icon button'} onClick={() => resetStates()}>
            Create Another
            </div>
        </div>
    )
    const success_screen = (
        <>
        <div className="success-div sec">
            <success><AiOutlineCheckCircle /></success><br />
            Success! 
            {igLimitReached && " The daily Instagram API limit has been reached, but your post has been added to the queue and will be posted as soon as possible."}
            {isX && twitterError && " The daily X API limit has been reached, but your post has been posted locally."}

        </div>
            {isIg && igLimitReached == false &&
        <a href={igUrl} target='_blank'>
        <div className={'icon'}>
        <BsInstagram /> View on Instagram
        </div>
        </a>
            }
            {isX && xId != null &&
            <a href={"https://twitter.com/jakobsitter/status/"+xId} target='_blank'>
            <div className={'icon'}>
            <FaXTwitter /> View on X
            </div>
            </a>
            }
        <div className={'icon button'} onClick={() => resetStates()}>
        Create Another
        </div>
        <div></div>
        </>
    )
    const generatedimg_screen = (
        <div className="sec">
                            <div className='img'>
                            <img src={img}></img>
                            </div>
                            <>
                            <div className={'icon'} style={{'background':'#c8fec8'}} onClick={() => setScreen('default_screen')}>
                            <BsEmojiHeartEyes /> Use this Photo
                            </div>
                            <div className={'icon'} style={{'background':'#fff5cb'}} onClick={() => setScreen('generate_screen')}>
                            <BsEmojiAngry /> Try again
                            </div>
                            <div className={'icon'} style={{'background':'#ffd9d9'}} onClick={() => {setScreen('default_screen'); resetStates();}}>
                            <BsXOctagon /> Cancel
                            </div>
                            </>
        </div>
    )
    const pfps = [pfp0, pfp1, pfp2, pfp3];
    useEffect(() => {
        if(img) {
            setType('image')
            setIg(true)
            console.log("type set to image")
        } else {
            setType('text')
            setIg(false)
        };
    }, [img])
    const updatePfp = () => {
        var min = 0;
        var max = 3;
        if(activePfp >= max) {
            setPfp(0)
        } else {
            setPfp(activePfp+1)
        }
    }
    const generate = async (prompt) => {
        if(prompt) {
            console.log('generating')
            setLoading(true)
            setScreen('loading_screen')
            const result = await axios.post(`https://api.jakobseeder.pro/`, {
                prompt : "a photo of TOK "+prompt,
            }).then((response) => {
                const json = response.data;
                console.log('json', json);
                updateImg(json[0]);
                console.log(img)
                setLoading(false)
                setIsImg(true)
                changeMode("generated")
                setGenerated(true)
                setScreen('generatedimg_screen')
            })
            .catch((error) => {
            console.log(error);
                //setError(true)
                setScreen('error_screen')
                setLoading(false)
            });
        } else {
            console.log('no prompt')
        }
    }
    const submit = async caption => {
        setLoading(true)
        setScreen('loading_screen')
        console.log(contentType)
        const result = await axios.post(`https://api.jakobseeder.pro/setcaption`, {
            prompt : ""+prompt,
            type: contentType,
            caption : caption,
            img_id: img,
            isIg: isIg,
            isX: isX,
            nsfw: false,
            author: "jakob",
            pfp: activePfp
         }).then((response) => {
            const json = response.data;
            console.log(json)
           //props.pushPost(json.cockpit);
            /*if(isIg){
                setIgUrl(json.cockpit.instagram_url);
            }*/
            setScreen('success_screen')
            //resetStates()
            if(json.success == true){
                if(json.ig_limit == true){
                    setIgLimitReached(true)
                } else {
                    setIgId(json.ig_url)
                }
                if(json.tweetSuccess == false){
                    setTwitterError(true)
                } else {
                    setTwitterError(false)
                }
                if(json.cockpit.tweet_id != null) {
                    setXId(json.cockpit.tweet_id)
                } else {
                    setXId(null)
                }

        //       setPostId(json.cockpit._id)
    //         setLoading(false)
            }
            
        })
        .catch((error) => {
        console.log(error);
            setScreen('error_screen')
        });

    }
    const transitions = useTransition(activeScreen, {
        from: { opacity: 0, 'transform': 'scale(0)', maxHeight: "0px" },
        enter: { opacity: 1, 'transform': 'scale(1)', maxHeight: "1000px" },
        leave: { opacity: 0, 'transform': 'scale(0)',  maxHeight: "0px" },
        duration: 100,
        exitBeforeEnter: true
    })
    return(
        <React.Fragment>
  
            <div className={"create-widget disabled"}>
            <div className="pfp" onClick={() => updatePfp()}>
                            <div className='shuffle_icon'>
                            <IoShuffleSharp />
                            </div>
                            <img src={pfps[activePfp]}></img>
            </div>
                <div className='inner'>
                    <div className='icons'>
                        {transitions((styles, item) => (
                        <animated.div style={styles}>
                        {activeScreen == 'default_screen' &&
                            default_screen
                        }
                        {activeScreen == 'generate_screen' &&
                            generate_screen
                        }
                        {activeScreen == 'generatedimg_screen' &&
                            generatedimg_screen
                        }
                        {activeScreen == 'loading_screen' &&
                            <LoaderSmall />
                        }
                        {activeScreen == 'success_screen' &&
                            success_screen
                        }
                        {activeScreen == 'error_screen' &&
                           error_screen
                        }
                        </animated.div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default CreateWidget;