import logo from './logo.svg';
import './App.css';
import Menu from './Menu.js';
import GenImage from './GenImage.js';
import Home from './Home.js';
import { Router, Routes, Route, useLocation, BrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react'
import Create from './Create.js';
import CreateNew from './CreateNew.js';
import Loader from './Loader.js';
import Info from './Info.js';
import SingleImage from './SingleImage.js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Map from "./Map.js"
import { useFetch } from './Hooks/useFetch';
import {io} from 'socket.io-client'

function App() {
  const { status: queue_status, data: queued_posts } = useFetch('https://api.jakobseeder.pro/in_queue');
  const location = useLocation();
  console.log(queued_posts)
  return (
    <div className="App light">
      <Menu queued_posts={queued_posts}/>
        <Routes location={location}>
        <Route path="/" element={<Home />} />
        <Route path="/item/:id" element={<SingleImage />} />
        <Route path="/generate" element={<CreateNew />} />
        <Route path="/info" element={<Info />} />
        <Route path="/loader" element={<Loader />} />
        <Route path="/map" element={<Map />} />
        </Routes>
    </div>
  );
}

export default App;
