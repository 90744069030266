import React from 'react';
function Info() {
    return(
        <div className="info_page">
            <div className="text">
            <black>Welcome to jakobseeder, the trailblazing platform that effortlessly transforms your thoughts into captivating messages and AI-generated photos directly onto Jakob Sitter's personal social media accounts. Elevate your digital presence with a touch of Jakob's flair!</black>
<br /><br />
<headline>✨ Why jakobseeder?</headline><br />
Unleash the power of personalized messaging and AI-generated visuals on Jakob Sitter's social platforms. Engage your audience with unique content that resonates with Jakob's distinctive style.
<br /><br />
<headline>💡 Seeding Brilliance, One Message at a Time</headline><br />
Craft short, impactful messages that reflect your personality and values. jakobseeder's intuitive interface makes it a breeze to share your thoughts with the world, all while seamlessly integrating Jakob's essence.
<br /><br />
<headline>📸 AI-Enhanced Visuals</headline><br />
Experience the magic of AI-generated photos featuring Jakob. Our cutting-edge technology transforms your ideas into stunning visuals that capture attention and leave a lasting impression on Jakob Sitter's audience.
<br /><br />
<headline>🚀 Maximize Visibility</headline><br />
Ride the wave of Jakob's influence! jakobseeder ensures your content reaches a broader audience, helping you connect with enthusiasts who appreciate the blend of creativity and technology.
<br /><br />
<headline>🔐 Privacy First: Safeguarding Your Digital Space</headline><br />

At jakobseeder, privacy is a cornerstone of our commitment. In our quest to prioritize your privacy, we've embraced a unique approach to ownership—every piece of content crafted on our platform is attributed to Jakob. Your insightful messages and AI-generated masterpieces find a home as valuable additions to Jakob's digital realm.

            </div>
        </div>
    );
}

export default Info;