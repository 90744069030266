import { NavLink, useLocation } from "react-router-dom";
import React, {useState, useEffect} from 'react';
import { AiOutlineMenu, AiFillInstagram, AiOutlineInfoCircle } from 'react-icons/ai';
import { BsChatSquareText, BsCamera, BsEmojiHeartEyes, BsEmojiAngry, BsXOctagon, BsInstagram, BsChevronDown, BsCheck } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";

import { BiTimer, BiTimeFive } from 'react-icons/bi';
function Menu(props) {
    const [isLight, setLight] = useState(true);
    const { pathname } = useLocation();
    console.log(pathname)
    useEffect(() => {
        document.body.classList.toggle('light', isLight);
        //document.body.classList.add('raspi_display')
      },[isLight])
    const mobileChange = (e) => {
        props.history.push(`/${e.target.value}`);
    }
    const [isMenu, setMenu] = useState(false);
    return(
        <>
        <div className="menu">
            <div className="menu_toggle" onClick={() => setMenu(!isMenu)}><AiOutlineMenu /></div>
            <div className={isMenu ? "header open":"header closed"}>
                <div className="toggle" onClick={() => setMenu(!isMenu)}><AiOutlineMenu /></div>
                    <div className="logo"><NavLink to="/">jakobseeder™</NavLink></div>
                <div className="submenu">
                
                <NavLink to="/info">
                    <div className="item">
                        <div className="icon"><AiOutlineInfoCircle /></div><div className="label">Info</div>
                    </div>
                </NavLink>
                <a href="https://instagram.com/jakobsitter" target="_blank">
                    <div className="item">
                        <div className="icon"><BsInstagram /></div><div className="label">Instagram</div>
                    </div>
                </a>
                <a href="https://twitter.com/jakobsitter" target="_blank">
                    <div className="item">
                        <div className="icon"><FaXTwitter /></div><div className="label">X</div>
                    </div>
                </a>
                </div>
            </div>
            {props.queued_posts.length > 0 &&
                <div className="queued"><BiTimeFive /> {props.queued_posts.length} posts in queue</div>
            }
                        <ul onClick={() => setMenu(!isMenu)}>
                <li><NavLink to="info">Info</NavLink></li>
                <li><a href="https://instagram.com/jakobsitter" target="_blank">Instagram</a></li>
            </ul>
        </div>
        </>
    );
}
export default Menu;