import {useState, useEffect} from 'react';
const cache = {};

export const useFetch = (url) => {
    const [status, setStatus] = useState('idle');
    const [data, setData] = useState([]);
    const pushPost = async (post) => {
        setData((data) => [...data, post])
    }
    useEffect(() => {
        if (!url) return;

        const fetchData = async () => {
            setStatus('fetching');

                const response = await fetch(url+'', {
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', "api-key": "API-b5a39c126b15e8297e0ba321c9a3d69823f4836a" }
                });
                const data = await response.json();
                cache[url] = data; // set response in cache;
                setData(data);
                setStatus('fetched');
            
        };

        fetchData();
    }, [url]);

    return { status, data, pushPost };
};
