import React, {useEffect} from 'react';
import Metadata from './ReplicateImages/metadata.json';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { AiFillHeart, AiFillInstagram } from 'react-icons/ai';
import { useFetch } from './Hooks/useFetch';
import moment from "moment";
import { useDoubleTap } from 'use-double-tap';
function SingleImage() {
    let { id } = useParams();
    const url = `https://jakobseederbackend.jakobsitter.com/api/content/item/images/${id}`;
    console.log(id)
    const { status, data } = useFetch(url);
    const [likes, setLikes] = useState(0);
    console.log(data);

    const upVote = async(id, likes) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "api-key": "API-b5a39c126b15e8297e0ba321c9a3d69823f4836a" },
            body: JSON.stringify({
                "data": {
                    "_id": id,
                    "likes": (likes + 1).toString()
                }
            })
        };
        await fetch('https://jakobseederbackend.jakobsitter.com/api/content/item/images', requestOptions)
            .then(response => response.json())
            .then(data => console.log(data));
    }
    const timeago = moment.unix(data.timestamp);
  const [trigger, setTrigger] = useState(false);
  const bind = useDoubleTap((event) => {
    if(!trigger){
    setLikes(likes+1); upVote(id, likes);
    setTrigger(true);
    setTimeout(function(){
      setTrigger(false);
    }, 1000)
  } else {
    return false
  }
  });
    useEffect(() => {
        if(data.likes) {
            setLikes(parseInt(data.likes));
        }
    }, [data]);
    return(
        <div className="single_page">
            {data.image_id &&
            <React.Fragment>
            <div className="left">
              <div className={"image_container"} {...bind}>
              <img src={"https://api.jakobseeder.pro/images/"+data.image_id+".png"} />
              </div>
            </div>
            <div className="right">
            <div className="text">
              <div className="caption">
                {data.caption}
              </div>
              <div className="list">
                <div className="item ">
                  <label>Created: </label>
                {timeago.fromNow()}
                </div>
                <div className="item ">
                <label>Prompt: </label>
                {data.prompt}
                </div>
                {data.author != null &&
                <div className="item ">
                <label>Creator: </label>
                {data.author}
                </div>
              }
                {data.instagram_url != "" &&
                <div className="item ">
                <label>Posted to: </label>
                <a href={data.instagram_url}><AiFillInstagram /></a>
                </div>
                }
            </div>
            </div>
            </div>
            </React.Fragment>
}
        </div>
    );
}

export default SingleImage;