import React from 'react';
import {ReactComponent as LoaderFace} from './Img/LoaderFace.svg';

function Loader(props) {
    return(
        <div className="loader">
            <div className="loader_inner">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    );
}

export default Loader;