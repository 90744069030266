import useMousePosition from './useMousePosition';
import React, { useState, useEffect } from 'react';
import useSound from 'use-sound';
import button from './Sounds/click.m4a';
import Metadata from './ReplicateImages/metadata.json';
import moment from 'moment';
import { NavLink } from "react-router-dom";
import { useFetch } from './Hooks/useFetch';
import BackgroundGrid from './BackgroundGrid';
import Feed from './Feed';
import NewGrid from './NewGrid';
import Loader from './Loader';
import { BsGrid3X3, BsList, BsMap } from 'react-icons/bs';
import Select from 'react-select';
import CreateWidget from './CreateWidget';
function Home() {
    const [play, {stop}] = useSound(
        button,
        { volume: 0.1 }
      );
    //Fetch data from backend
    const [isLoading, setLoading] = useState(true);
    const [isGrid, switchGrid] = useState(true);
    const [sliderValue, setSliderVal] = useState(1);
    const url = `https://server.jakobseeder.pro/api/content/items/new_img`;
    const { status, data, pushPost } = useFetch(url);
    const [numPosts, setNumPosts] = useState();
    const options = [
        { value: 'newest', label: 'Newest' },
        { value: 'trending', label: 'Trending' },
        { value: 'oldest', label: 'Oldest' },
      ];
      const [selectedOption, setSelectedOption] = useState(options[0]);
    useEffect(() =>{
      setNumPosts(data.length)
      console.log(numPosts)

    }, [data])
    console.log(status)
    if(status == "fetched"){
      console.log(data)
    
    return (
        <div className={"home"}>
            <Feed data={data} pushPost={pushPost} numPosts={numPosts} />
        </div>
    );
    } else {
        return (
            <div className={"home"}>
                    <Loader />
                </div>
        );
    }
}
export default Home;